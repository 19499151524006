* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Urbanist", sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style-type: none;
}
button {
  border: none;
}
